import { ForwardIcon, BackwardIcon } from '@heroicons/react/24/solid';
import React, { useMemo, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';

import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@4.4.168/build/pdf.worker.min.mjs`;

export const PDFViewer: React.FC<{ pdfUrl?: string }> = ({
  pdfUrl = 'https://raw.githubusercontent.com/the-debug-arena/React-Pdf-Multer-Frontend/38f95fc8e11488253059f92a46d8d1fc5ba5507b/src/1.pdf',
}) => {
  const [numPages, setNumPages] = useState<number | undefined>();
  const [pageNumber, setPageNumber] = useState<number | undefined>(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () =>
    setPageNumber(Number(pageNumber) - 1 <= 1 ? 1 : Number(pageNumber) - 1);

  const goToNextPage = () =>
    setPageNumber(
      Number(pageNumber) + 1 >= Number(numPages)
        ? numPages
        : Number(pageNumber) + 1,
    );

  const memoizedFile = useMemo(() => ({ url: pdfUrl }), [pdfUrl]);

  return (
    <div className='w-full h-full rounded-lg 2xl:flex 2xl:flex-col 2xl:items-center'>
      <nav>
        <button className='text-secondary' onClick={goToPrevPage}>
          <BackwardIcon className='text-secondary hover:text-primary h-4 w-4' />
        </button>
        <button className='ml-4' onClick={goToNextPage}>
          <ForwardIcon className='text-secondary h-4 w-4 hover:text-primary' />
        </button>
      </nav>
      <p>
        Page {pageNumber} of {numPages}
      </p>
      <Document file={memoizedFile} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} scale={1} />
      </Document>
    </div>
  );
};
