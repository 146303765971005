import Cookies from 'js-cookie';

import { User } from '../features/auth';

const storagePrefix = 'awesumedge_';

const storage = {
  getToken: () => {
    const token = Cookies.get(`${storagePrefix}token`);
    return token ? JSON.parse(token) : null;
    // return window.sessionStorage.getItem(`${storagePrefix}token`)
    //   ? JSON.parse(
    //       window.sessionStorage.getItem(`${storagePrefix}token`) as string,
    //     )
    //   : null;
  },
  getUser: () => {
    const user = Cookies.get(`${storagePrefix}user`);
    return user ? JSON.parse(user) : null;
    // return window.sessionStorage.getItem(`${storagePrefix}user`)
    //   ? JSON.parse(
    //       window.sessionStorage.getItem(`${storagePrefix}user`) as string,
    //     )
    //   : null;
  },
  setToken: (token: string) => {
    Cookies.set(`${storagePrefix}token`, JSON.stringify(token), { expires: 1 });
    // window.sessionStorage.setItem(
    //   `${storagePrefix}token`,
    //   JSON.stringify(token),
    // );
  },
  setUser: (user: User) => {
    Cookies.set(`${storagePrefix}user`, JSON.stringify(user), { expires: 1 });
    //window.sessionStorage.setItem(`${storagePrefix}user`, JSON.stringify(user));
  },
  clearToken: () => {
    // console.log('clear token');

    // window.sessionStorage.removeItem(`${storagePrefix}token`);
    Cookies.remove(`${storagePrefix}token`);
  },
  clearUser: () => {
    //window.sessionStorage.removeItem(`${storagePrefix}user`);
    Cookies.remove(`${storagePrefix}user`);
  },
};

export default storage;
