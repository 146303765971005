import { useNavigate } from 'react-router-dom';

import { landingBg } from '../../../assets';
import storage from '../../../utils/storage';

export const Hero = () => {
  const navigate = useNavigate();
  const loggedInUser = storage.getUser();

  console.log({ loggedInUser });

  return (
    <div className='h-[450px] md:min-h-[64vh] lg:min-h-[500px] xl:min-h-[94vh] py-8 lg:py-0 bg-[#F4F4F4] flex px-8'>
      <div className='flex flex-col justify-center flex-1 w-full h-full'>
        {loggedInUser && (
          <p className='mb-2 text-[14px] md:text-[18px] xl:text-2xl text-[#4F4F4F] font-semibold'>
            Welcome {loggedInUser.first_name},
          </p>
        )}
        <h1 className='text-2xl sm:text-4xl font-bold md:text-6xl text-secondary'>
          Start a career in Tech by learning relevant skills
        </h1>

        <div className='flex flex-col lg:hidden md:flex h-[24vh] items-center justify-center'>
          <img alt='hero-img' src={landingBg} className='h-full self-center' />
        </div>

        <p className='my-4 text-[12px] md:text-[16px] xl:text-xl text-[#4F4F4F]'>
          Learn the required skills that would make your transition into tech
          seamless either through our live sessions, or self-paced courses.{' '}
        </p>

        <button
          onClick={() => navigate(`/courses`)}
          type='button'
          className={`bg-primary hover:bg-secondary text-white rounded w-60 md:w-60 p-1 md: py-2 mb-4 text-[14px] md:text-[16px] font-medium self-center lg:self-start`}
        >
          Explore Our Courses
        </button>
      </div>

      <div className='flex-col items-end justify-center flex-1 hidden lg:justify-normal sm:-mr-8 lg:flex'>
        <img alt='hero-img' src={landingBg} className='h-full object-contain' />
      </div>
    </div>
  );
};
