import { CameraIcon, UserIcon } from '@heroicons/react/24/solid';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { ToastContainer } from 'react-toastify';
import { object, string, TypeOf } from 'zod';

import { PasswordInput, Spinner } from '../../../../components/Elements';
import { Head } from '../../../../components/Head';
import { ErrorProps } from '../../../../types';
import { notifyError, notifySuccess } from '../../../../utils/notifications';
import storage from '../../../../utils/storage';
import styles from '../../../../utils/styles';
import { useUploadImage } from '../../../admin/livesessions/api/scheduleLiveSession';
import { Loader } from '../../../liveworkshops/components/Loader';
import { useChangeDetails, useChangePassword } from '../api';

type CountryOption = {
  label: string;
  value: string;
};

const profileSchema = object({
  image_id: string().optional(),
  first_name: string().optional(),
  last_name: string().optional(),
  country: string().optional(),
  email: string().optional(),
});

const passwordSchema = object({
  current_password: string().min(1, 'Current password is required'),
  new_password: string().min(
    8,
    'New password must not be less than 8 characters',
  ),
  confirm_new_password: string().min(
    8,
    'Confirm new password must not be less than 8 characters',
  ),
}).refine((data) => data.new_password === data.confirm_new_password, {
  message: "Passwords don't match",
  path: ['confirm_new_password'],
});

type ProfileInput = TypeOf<typeof profileSchema>;
type PasswordInput = TypeOf<typeof passwordSchema>;

export const Settings = () => {
  const countries: CountryOption[] = countryList().getData();
  const user = storage.getUser();
  const [preview, setPreview] = useState<string | ArrayBuffer | null>(null);
  const profileDefaultValues = {
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    image_id: user.image?.id ?? '',
    country: user.country ?? '',
    photo_url: user.image?.presigned_url,
  };

  const passwordDefaultValues = {
    current_password: '',
    new_password: '',
    confirm_new_password: '',
  };

  const {
    register: profileRegister,
    control: profileControl,
    formState: { errors: profileErrors },
    handleSubmit: handleProfileSubmit,
    watch: profileWatch,
    setValue: setProfileValue,
  } = useForm<ProfileInput>({
    resolver: zodResolver(profileSchema),
    defaultValues: profileDefaultValues,
  });

  const {
    register: passwordRegister,
    formState: { errors: passwordErrors },
    handleSubmit: handlePasswordSubmit,
    reset: resetPasswordUpdateFields,
  } = useForm<PasswordInput>({
    resolver: zodResolver(passwordSchema),
    defaultValues: passwordDefaultValues,
  });

  const {
    mutate: uploadImage,
    data: imgData,
    isPending: isImgUploadPending,
    isSuccess: isImgUploadSuccess,
    isError: isImgUploadError,
    reset: resetImgUpload,
  } = useUploadImage();

  const {
    mutate: changePassword,
    //data: passwordChangeResp,
    isPending: isPasswordChangePending,
    isSuccess: isPasswordChangeSuccess,
    isError: isPasswordChangeError,
    reset: resetPasswordChange,
    error: passwordChangeError,
  } = useChangePassword();

  if (isPasswordChangeSuccess) {
    notifySuccess('Password updated successfully', 3000);
    resetPasswordChange();
    resetPasswordUpdateFields();
  }

  if (isPasswordChangeError) {
    const error = passwordChangeError as unknown as ErrorProps;

    if (error.response.data) {
      const obj = error.response.data.data;

      const firstErrorKey = Object.keys(obj)[0];
      const firstErrorValue = obj[firstErrorKey][0];

      const msg = firstErrorValue;
      notifyError(msg, false);
    }
    resetPasswordChange();
  }

  const {
    mutate: changeDetails,
    //data: detailsChangeResp,
    isPending: isDetailsChangePending,
    isSuccess: isDetailsChangeSuccess,
    isError: isDetailsChangeError,
    reset: resetDetailsChange,
    error: detailsChangeError,
  } = useChangeDetails();

  if (isDetailsChangeSuccess) {
    notifySuccess('Details updated successfully', 3000);
    resetDetailsChange();
  }

  if (isDetailsChangeError) {
    const error = detailsChangeError as unknown as ErrorProps;

    if (error.response.data) {
      const obj = error.response.data.data;

      const firstErrorKey = Object.keys(obj)[0];
      const firstErrorValue = obj[firstErrorKey][0];

      const msg = firstErrorValue;
      notifyError(msg, false);
    }
    resetDetailsChange();
  }

  const fieldsToCheck: (keyof ProfileInput)[] = [
    'first_name',
    'last_name',
    'image_id',
    'country',
  ];

  const watchedValues = profileWatch();

  const handleProfileUpdate = (data: ProfileInput) => {
    const modifiedFields: string[] = [];

    fieldsToCheck.forEach((field) => {
      const isModified =
        (profileDefaultValues as ProfileInput)[field] !== watchedValues[field];
      if (isModified) {
        modifiedFields.push(field);
      }
    });

    const isModified = modifiedFields.length > 0;

    if (isModified) {
      const finalData: { [x: string]: any } = {};
      modifiedFields.forEach((field) => {
        finalData[field] = (data as any)[field];
      });

      // API call to update profile details
      console.log('Profile update data:', finalData);
      changeDetails(finalData);
    } else {
      notifyError('No changes detected', false);
    }
  };

  const handleChangePassword = (data: PasswordInput) => {
    // API call to change password
    console.log('Password change data:', {
      current_password: data.current_password,
      new_password: data.new_password,
    });
    changePassword({
      current_password: data.current_password,
      new_password: data.new_password,
    });
  };

  if (isImgUploadSuccess) {
    setProfileValue('image_id', imgData.data?.id);
    resetImgUpload();
  }

  if (isImgUploadError) {
    notifyError('Failed to upload image. Please try again.', false);
    resetImgUpload();
  }

  return (
    <>
      <Head title='Settings' />

      <div className=' min-h-screen'>
        <form
          autoComplete='off'
          className='flex flex-col sm:flex-row'
          onSubmit={handleProfileSubmit(handleProfileUpdate)}
        >
          <div className='flex-[0.2]'>
            <div className={styles.formControl}>
              <div className=''>
                <Controller
                  control={profileControl}
                  name='image_id'
                  render={() => {
                    return (
                      <label className='flex flex-col items-center sm:items-start'>
                        {preview || profileDefaultValues.photo_url ? (
                          <img
                            className='h-[150px] w-[150px] 2xl:h-[250px] 2xl:w-[250px] rounded-full'
                            src={
                              (preview ||
                                profileDefaultValues.photo_url) as string
                            }
                            alt='profile pic'
                          />
                        ) : (
                          <div className='relative h-[150px] w-[150px] 2xl:h-[250px] 2xl:w-[250px] rounded-full bg-slate-200 flex items-center justify-center border-[1px] border-slate-300'>
                            <UserIcon
                              color=''
                              className='h-[75px] 2xl:h-[150px] text-slate-400'
                            />
                            <span className='absolute top-0 right-4 2xl:right-12'>
                              <CameraIcon className='h-6 w-6 text-slate-500' />
                            </span>
                          </div>
                        )}

                        <div className='text-sm text-slate-500 mt-2 w-[150px] 2xl:w-[250px] text-center'>
                          Upload Photo
                        </div>
                        <input
                          className='hidden'
                          accept='image/gif,image/jpeg,image/jpg,image/png'
                          type='file'
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            const file = e.target.files
                              ? e.target.files[0]
                              : null;

                            const reader = new FileReader();
                            reader.onload = () => {
                              setPreview(reader.result);
                            };
                            file && reader.readAsDataURL(file);

                            const formData = new FormData();
                            formData.append('file', file as File);
                            uploadImage(formData);
                          }}
                        />

                        {profileErrors['image_id']?.message && (
                          <div
                            role='alert'
                            aria-label={profileErrors['image_id'].message}
                            className='text-sm font-semibold text-red'
                          >
                            {profileErrors['image_id'].message}
                          </div>
                        )}
                      </label>
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className='flex-[0.8]'>
            <h3 className='text-primary mb-2 font-semibold'>
              Personal Information
            </h3>

            <div className='flex flex-col sm:flex-row mt-8 sm:mt-0'>
              <div className={`${styles.formControl} flex-[0.5] sm:mr-4`}>
                <label htmlFor='first_name' className={styles.label}>
                  First Name
                </label>
                <input
                  {...profileRegister('first_name')}
                  placeholder='Enter first name'
                  type='text'
                  className={`${styles.input}`}
                  data-cy='first_name'
                />
                <p className={styles.error}>
                  {profileErrors.first_name?.message as string}
                </p>
              </div>

              <div className={`${styles.formControl} flex-[0.5]`}>
                <label htmlFor='last_name' className={styles.label}>
                  Last Name
                </label>
                <input
                  {...profileRegister('last_name')}
                  placeholder='Enter last name'
                  type='text'
                  className={`${styles.input}`}
                  data-cy='last_name'
                />
                <p className={styles.error}>
                  {profileErrors.last_name?.message as string}
                </p>
              </div>
            </div>

            <div className='flex flex-col sm:flex-row sm:mt-0'>
              <div className={`${styles.formControl} flex-[0.5] sm:mr-4`}>
                <label htmlFor='country' className={styles.label}>
                  Country
                </label>

                <Controller
                  name='country'
                  control={profileControl}
                  render={({ field: { onChange } }) => (
                    <Select
                      options={countries}
                      formatOptionLabel={(option: CountryOption) => (
                        <div className='flex items-center'>
                          <span
                            className={`fi fi-${option.value.toLowerCase()} mr-2`}
                          ></span>
                          {option.label}
                        </div>
                      )}
                      getOptionLabel={(option: CountryOption) => option.label}
                      getOptionValue={(option: CountryOption) => option.value}
                      defaultValue={countries.find(
                        (country) =>
                          country.value === profileDefaultValues.country,
                      )}
                      onChange={(option) => onChange(option?.value)}
                    />
                  )}
                />
                <p className={styles.error}>
                  {profileErrors.country?.message as string}
                </p>
              </div>

              <div className={`${styles.formControl} flex-[0.5]`}>
                <label htmlFor='email' className={styles.label}>
                  Email Address
                </label>
                <input
                  {...profileRegister('email')}
                  type='text'
                  className={`${styles.input} readonly-input`}
                  data-cy='email'
                  readOnly={true}
                />
                <p className={styles.error}>
                  {profileErrors.email?.message as string}
                </p>
              </div>
            </div>

            <button
              type='submit'
              className='disabled:bg-slate-300 mb-8 flex font-semibold items-center justify-center bg-secondary hover:bg-primary text-sm h-[40px] min-w-[68px] px-8 rounded-md text-white shadow-all-sides'
            >
              {isDetailsChangePending && (
                <Spinner className='mr-2' size='sm' variant='light' />
              )}
              Update Profile
            </button>
          </div>
        </form>

        <form
          autoComplete='off'
          className='flex flex-col sm:flex-row mt-4'
          onSubmit={handlePasswordSubmit(handleChangePassword)}
        >
          <div className='flex-[0.2]'></div>
          <div className='flex-[0.8]'>
            <h3 className='text-primary mb-2 font-semibold'>Change Password</h3>

            <div className='flex flex-col sm:flex-row mt-8 sm:mt-0'>
              <div className={`${styles.formControl} flex-[0.5] sm:mr-4`}>
                <label htmlFor='current_password' className={styles.label}>
                  Enter Current Password
                </label>

                <PasswordInput
                  placeholder='Enter password'
                  registration={passwordRegister('current_password')}
                  data-cy='current_password'
                />
                <p className={styles.error}>
                  {passwordErrors.current_password?.message as string}
                </p>
              </div>

              <div className={`${styles.formControl} flex-[0.5]`}>
                <label htmlFor='new_password' className={styles.label}>
                  Enter New Password
                </label>

                <PasswordInput
                  placeholder='Enter password'
                  registration={passwordRegister('new_password')}
                  data-cy='new_password'
                />
                <p className={styles.error}>
                  {passwordErrors.new_password?.message as string}
                </p>
              </div>
            </div>

            <div className='flex flex-col sm:flex-row sm:mt-0'>
              <div className={`${styles.formControl} flex-[0.5] sm:mr-4`}></div>

              <div className={`${styles.formControl} flex-[0.5]`}>
                <label htmlFor='confirm_new_password' className={styles.label}>
                  Confirm New Password
                </label>

                <PasswordInput
                  placeholder='Re-enter new password'
                  registration={passwordRegister('confirm_new_password')}
                  data-cy='confirm_new_password'
                />
                <p className={styles.error}>
                  {passwordErrors.confirm_new_password?.message as string}
                </p>

                <button
                  type='submit'
                  className='disabled:bg-slate-300 mt-8 flex font-semibold items-center justify-center bg-secondary hover:bg-primary text-sm h-[40px] min-w-[68px] px-8 rounded-md text-white shadow-all-sides'
                >
                  {isPasswordChangePending && (
                    <Spinner className='mr-2' size='sm' variant='light' />
                  )}
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      {isImgUploadPending && <Loader isLoading={isImgUploadPending} />}
      <ToastContainer />
    </>
  );
};
